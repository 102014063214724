import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          
          <p>
            Website thuộc hệ thống Greatnet 
          </p>
          <p>
            Tài sản của Đinh Văn Thuận 
          </p>
          <p>
            Mọi công việc xin liên hệ theo email: Greatsuncalls@gmail.com
          </p>
        </header>
      </div>
    );
  }
}

export default App;
